<template>
  <div class="page">
   <headerPreview v-if="is_preview"></headerPreview>
   <Headers v-else ></Headers>
    <div class="detail">
      <div class="cityname">{{ cityinfo.city_name }}</div>
      <div class="introbox">
        <div class="introleft">
          <img src="../../../assets/collect/infoleft.png" alt="" />
        </div>

        <div class="info">{{ cityinfo.intro }}</div>
        <div class="introright">
          <img src="../../../assets/collect/inforight.png" alt="" />
        </div>
      </div>
      <div class="line1">
        <div class="videoarea" >
           <video  class="video"
            v-if="cityinfo.video_url"
            width="660"
            height="375"
            :src="cityinfo.video_url"
            controls :poster="cityinfo.video_cover_url">
            <source :src="cityinfo.video_url"  type="video/mp4">
           </video>
           <img v-else :src="cityinfo.video_cover_url" style="width:660px;height:375px"  alt=""/>
    
          <div class="videoTitle">城市宣传视频</div>
        </div>
        <div class="shouce">
          <img @click="openHandbook(cityinfo)" :src="cityinfo.handbook_cover_url" alt="" />
          <div class="shouceTitle">{{cityinfo.handbook_title}}</div>
        </div>
      </div>
      <div class="line2">
        <div class="button" @click="toBigdata">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAAEgBckRAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAMKADAAQAAAABAAAAMAAAAAD4/042AAADVElEQVRoBe2Yv2sUQRTHE7WwEUXri4goiJIU1nqKdoIIIsRCsbTwR8BGSWdj6Y/Df8DGSrBJp2hiZ6eGiIigpBCEgBBQDzXn5603y+zu/Nzdu5O4D743s2/ee9/3ZnZndm9sLER6vd6MstugOijbqu9vsZ4AqYjHuPyIRto4wWlGoLzSrJTC2xrJx5H4UDauhEIfJOPzXG9XOtjuqL60BWoMHqBXE/RKN5Z+hoHom9D9LBgRRenSDsYqqhortPil9oXB0SkKOVHKadJp9VPqkPZvPb3CtGrGYndZN5a+ySFvk7k2OXSUBelkVlnpy7cUvCoLmJN7esR0lsRIH8j110hvo+iSGrDdnzPIX6a1Jgw4rGCR3tJ5a7mGIc3GND46XXBalLmDNE+ALWCRiuZD0vYS9AOfswTrQXTXMpao09VwGE07xrwJegnI8L6DoLBnOWxHOMQ6nAXPwWfwHbwFHXCgVFo4HgexshZEFhvVYH/dSoTxQ4NDtMpK0Ayszxnw7iWqbG6lNn15sFbBHFuIHCJe8RIQWM6ywoGObhmSRz4G72ZHgEuWIC3I5YxwSgiBq0o5gJwSQuAKIKebU6oSLDqjMxhCYD1UWOT5ygQEeUaQPIm8RaUvdT6SdT7Ovb4L3AZL4AeQY/MFuFCpdAJcAyHyC6NtUWQ4mN6bfWRXg0iI8tUXyTF+yEmC47TDOWjIRxAUxGN0TCcJeZJ1+5D+Fd0os1NKZvpgyX6Xp39zSd/GrZmBZgaiZyDzGEd7aw7sAPL6dxhMAltc2SZegwUe9cwfRehKiY0oKhjJH8VhKsqJt5T+20qkW9a88k5N8u0SyUsWU33fbEaRV5ULgK/cd/7fRKv4JhHqKEC+pcpKFd+Es44C5ohU5gwXH/GtJHU9xLIDnQKtwGyWsXtcx05USwF60jyY8kEr9/ZOoD4N5Vb5BORP0BXaRpoZ4FbZCi6Cl6CsvMNxFkwMZUYhOgjegEHJFwKfqb0Ygp4E3UFlbYk7G1KIcxcisJwTT8GRkGADsPlIzEl2LuuB5ytggQDuj/UBZJ0L+YHrPRRhPCytJzGzf+MfSF5q2Q1uScck1hWggCUc9pmcRqB7zwrsNfFaVwDjm+CbyWnIui58T4bM2dD9PzPwB3gODSKO8Z8qAAAAAElFTkSuQmCC" alt="">
          <div class="name">土拍大数据</div>
        </div>
        <div class="titlebox">
          <img
            src="../../../assets/collect/gongshileft.png"
            class="leftgreen"
          />
          <div class="title" @click="toBigdata">土地成交数据</div>
          <img
            src="../../../assets/collect/gongshiright.png"
            class="rightgreen"
          />
        </div>
        <div class="selectbox">
          <el-select
            size="mini"
            style="width: 120px"
            v-model="currentyear"
            placeholder="请选择"
          >
            <el-option
              v-for="item in yearsList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
          v-if="cityinfo.level_name !== 'B'"
            size="mini"
            style="width: 120px;margin-left: 10px"
            v-model="currentshi"
            placeholder="请选择"
          >
            <el-option
              v-for="item in shiList"
              :key="item.value"
              :label="cityinfo.city_name + item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
           <el-select  size="mini"
            style="width: 260px;margin-left: 10px"
             v-model="currenttype" multiple placeholder="请选择">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="contentshow"  >
          <div class="itembox" @click="toBigdata">
            <div class="title">地块总宗数</div>
            <div class="counts">{{trade.pcs}}</div>
            <div class="unit">宗</div>
          </div>

          <div class="divider"></div>

          <div class="itembox"  >
            <div class="title">地块总面积</div>
            <div class="counts">{{trade.area}}</div>
            <div class="unit">平方米</div>
          </div>

          <div class="divider"></div>

          <div class="itembox"  @click="toBigdata">
            <div class="title">建筑总面积</div>
            <div class="counts">{{trade.building_area}}</div>
            <div class="unit">平方米</div>
          </div>

          <div class="divider"></div>

          <div class="itembox"  @click="toBigdata">
            <div class="title">成交总价</div>
            <div class="counts">{{trade.total_price}}</div>
            <div class="unit">万元</div>
          </div>

          <div class="divider"></div>

          <div class="itembox"  @click="toBigdata">
            <div class="title">平均楼面价</div>
            <div class="counts">{{trade.price}}</div>
            <div class="unit">元/平方米</div>
          </div>

          <div class="divider"></div>

          <div class="itembox"  @click="toBigdata">
            <div class="title">总溢价</div>
            <div class="counts">{{trade.premium_rate}}</div>
            <div class="unit">%</div>
          </div>
        </div>

      </div>
      <div class="line3">
        <div class="titlebox">
          <img
            src="../../../assets/collect/gongshileft.png"
            class="leftgreen"
          />
          <div class="title">公示展示地块</div>
          <img
            src="../../../assets/collect/gongshiright.png"
            class="rightgreen"
          />
        </div>
        <div class="citylist" v-if="citylist.length>0">
          <div v-for="item in citylist" :key="item.id"  :class="{cityitembtn:true, active: item.selected}" @click="changearea(item)">
            {{ item.city }}
          </div>
        </div>
        <el-table
          :data="tableData"
          style="width: 1200px; margin-bottom: 0px;height:700px;overflow:auto"
          row-key="id"
          height="700px"
          border
          class="custom-table"
           highlight-current-row 
          @row-click="handleRowClick"
         
        >
        <!--          -->
        <el-table-column label="序号"  min-width="45" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="width: 100%;text-align: center">
              {{ scope.$index + 1 }}
            </div>
           
          </template>
        </el-table-column>
         
          <el-table-column prop="cname"  align="center" label="城市" min-width="60" show-overflow-tooltip> 
          </el-table-column>
    
          <el-table-column prop="qname" v-if="cityinfo.level_name !== 'B'" align="center"  label="区县市" min-width="68" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="block" align="center" label="板块/街道" min-width="88" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="locationname" align="center" label="地块名称" min-width="180" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="location" align="center" label="地块位置" min-width="180" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="acreagemu" align="center" label="面积（亩）" min-width="70" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="useyears"  align="center" label="出让年限" min-width="80" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="usedetail" align="center" label="规划用途" min-width="80" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="volume" align="center" label="容积率" min-width="60" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="height" align="center" label="建筑高度" min-width="56" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="green" align="center" label="绿地率" min-width="60" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="status_txt" align="center" label="当前状态" min-width="80" show-overflow-tooltip> 
          </el-table-column>
        </el-table>
      </div>
      <div style="width: 100%; height: 100px"></div>
    </div>
  </div>
</template>

<script>
import { cityInfo,tradedata,tabledata } from "@/api/website/collect.js";

import Headers from '@/components/collect/header'
import headerPreview from '@/components/collect/headerPreview'
export default {
  components:{Headers,headerPreview},
  props:{
    is_preview:0
  },
  data() {
    return {
      city_id: null,
      tableData: [
     
      ],
      //获取当前年份
      currentyear: (new Date()).getFullYear(),
      currentshi:1,
      currenttype:[1,2],
      yearsList: [
        
      ],
       shiList: [
        {
          value: 1,
          label: "大市",
        },
        {
          value: 2,
          label: "市区",
        }
      ],
      typeList:[
        {
          value: 1,
          label: "住宅",
        },
        {
          value: 2,
          label: "商住",
        },
        {
          value: 3,
          label: "商服",
        },
        {
          value: 4,
          label: "其他",
        }
      ],
      trade:{
        pcs:211,
        area: 123456,
        building_area: 123456,
        total_price: 123456,
        price: 123456,
        premium_rate: 10
      },

      citylist: [
      ],

      cityinfo: {
        city_name: "苏州",
        intro: "幸福之城,福气苏州,福气苏州",
        video_url :'',
        video_cover_url :'',
        handbook_cover_url:"../../../assets/collect/shouce.png",
        handbook_url:'',
  
      },
    };
  },
  watch: {
    currentyear(val) {
      this.getTradeData();
    },
    currentshi(val) {
      this.getTradeData();
    },
    currenttype(val) {
      this.getTradeData();
    },
  },
  computed: {
    cityname() {
      return this.citylist.filter((item) => item.id == this.city_id)[0].city_name;
    },
  },
  created() {
  
  },

  methods: {
  } ,
  mounted() {
    this.createyearlist();
    this.city_id = this.$route.params.id;
    this.getCityInfo();
    this.getTradeData();
    this.getTableData();
  },

  methods: {
    createyearlist(){
      const currentyear = new Date().getFullYear()
      for(let i = 2023;i<=currentyear;i++){
        this.yearsList.push({
          value: i,
          label: i+'年'
        })
      }
    },




    toBigdata(){
     window.open('https://db.chinatupai.com/#/data/deal')
    },

    openHandbook(info){
      if(info.handbook_link){
        window.open(info.handbook_link)
      }else{
        window.open(info.handbook_url)
      }
    },
    
 
    changearea(item){
      this.citylist.forEach(element => {
        element.selected = false
      });
      item.selected = true
      this.select_city_id = item.id
      this.getTableData()
    },

    //获取列表
    getList() {
      allList({}).then((res) => {
        this.list = res.data;
      })
    },
    
    getCityInfo(){
      cityInfo({city_id:this.city_id}).then(res=>{
        this.cityinfo = res.data
      })
    },


    getTradeData(){
      let usecate = ''
      if(this.currenttype.length === 1){
        usecate = this.currenttype[0]
      }else{
       usecate = this.currenttype.join(',')
      }
  
      tradedata({city_id:this.city_id,year:this.currentyear,is_all:this.currentshi,usecate:usecate}).then(res=>{
        this.trade = res.data
      })
    },

    getTableData(){
       tabledata({city_id:this.city_id,select_city_id:this.select_city_id}).then(res=>{
         this.citylist = res.data.citylist
         this.tableData = res.data.tableData
       })
     },


    tableRowClassName(){
      return 'pointer-row'; // 为每一行添加自定义类名 
    },
     handleRowClick(row, event, column) {
      // console.log( event.target.parentNode.rowIndex - 1)
        if(this.is_preview){
           window.open('/#/preview/landdetail/'+row.id+'/'+this.city_id)
        }else{
           window.open('/#/collect/landdetail/'+row.id+'/'+this.city_id)
        }
        

        //  this.$router.push({path:'/collect/landdetail/'+row.id+'/'+this.city_id,})
    },
  },
};
</script>

<style lang="scss" scoped>
.line2{
   position: relative;
  .button{
    position: absolute;
    right:20px;
    top:20px;
    width: 134px;
    background: #00a3e4;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    .name{
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      line-height: 42px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    
    }
  }
}

.custom-table {

 
   ::v-deep .el-table__header-wrapper th {
    background-color: #009d70; // 设置表头颜色
    color: #ffffff; // 设置表头文字颜色
    font-size: 16px;
  }

 
   ::v-deep .el-table__body-wrapper .cell {
    padding-left:0;
    padding-right:0;
  }
   ::v-deep .el-table__body-wrapper  .el-table__cell{
    padding: 10px 2px;
   }
   

  ::v-deep .el-table__body-wrapper tr > td {
 
     color:#000;
  }

    ::v-deep .el-table__body-wrapper tr:hover > td {
     cursor: pointer; /* 设置鼠标样式为 pointer */
     background:#d8e2d8;
     color:#000;
     font-weight: bold;
  }
  
  
}


.citylist {
  display: flex;
  justify-content: center;
  .cityitembtn {
    font-family: Microsoft YaHei UI, Microsoft YaHei UI;
    font-weight: bold;
    font-size: 18px;
    color: #009d70;
    line-height: 21px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    border: solid 1px #009d70;
    display: inline-block;
    padding: 2px 10px;
    border-radius: 5px;
    margin: 20px 10px;
    cursor: pointer;
  }
  .active {
     color: #fff;
     background: #009d70;
      border: solid 1px #009d70;
  }
}

.titlebox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
   margin-bottom: 30px;
  .leftgreen {
    width: 20px;
    height: 20px;
  }
  .rightgreen {
    width: 20px;
  }
}

.line3 {
  margin-top: 30px;
  padding-bottom: 30px;
  width: 100%; // 修改: 设置宽度为100%
  height: auto; // 修改: 设置高度为auto
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(180, 212, 198, 0.8);
  border-radius: 10px 10px 10px 10px;
}



.contentshow {
  margin-left: 136px;
  margin-top: 20px;
  width: 928px;
  display: flex;
  .divider {
    width: 1px;
    height: 100px;
    background: #e5e5e5;
    margin: 0 20px;
  }
  .itembox {
    width: 152px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      width: 100%;
      height: 23px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: bold;
      font-size: 18px;
      color: #000000;
      line-height: 21px;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
    .counts {
      width: 100%;
      height: 60px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 24px;
      color: #8a8a8a;
      line-height: 60px;
      text-align: center;
    }

    .unit {
      width: 100%;
      height: 40px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 500;
      font-size: 20px;
      color: #8a8a8a;
      line-height: 20px;
      text-align: center;
    }
  }
}

.selectbox {
  // background: red;
  margin-top: 10px;
  display: flex;
  margin-left: 136px;
  width: 928px;
}
.titlebox {
  padding-top: 28px;
  width: 100%; // 修改: 设置宽度为100%
  height: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .leftgreen,
  .rightgreen {
    width: 285px;
    height: 26px;
    margin-top: 8px;
  }
  .title {
    width: 181px;
    height: 34px;
    font-family: DingTalk JinBuTi, DingTalk JinBuTi;
    font-weight: 400;
    font-size: 30px;
    color: #009d70;
    line-height: 35px;
    text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.3);
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin: 0 10px;
  }
}
.page {
  width: 100%;
  // height: 2367px;
  background: linear-gradient(180deg, #e5f6f7 0%, #fafdfd 100%);
  border-radius: 0px 0px 0px 0px;
}

.line1 {
  width: 1200px;
  height: 460px;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 0px 10px 10px 0px;
  .videoarea {
    width: 790px;
    height: 460px;
    background: linear-gradient(90deg, #00cb76 0%, #009b70 100%);
    box-shadow: 0px 4px 12px 0px #b4d4c6;
    border-radius: 10px 0px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .video {
      width: 660px;
      height: 375px;
    }
    .videoTitle {
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: bold;
      font-size: 20px;
      color: #ffffff;
      line-height: 24px;
      text-align: center;
      font-style: normal;
    }
  }
  .shouce {
    background: #ffffff;
    border-radius: 0px 10px 10px 0px;

    width: 410px;
    height: 460px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 274px;
      height: 375px;
    }
    .shouceTitle {
      margin-top: 10px;
      width: 274px;
      height: 23px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: bold;
      font-size: 20px;
      color: #000000;
      line-height: 23px;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
  }
}
.line2 {
  margin-top: 30px;
  width: 1200px;
  height: 248px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(180, 212, 198, 0.8);
  border-radius: 10px 10px 10px 10px;
}

.detail {
  width: 1200px;
  height: 1000px;

  // background: #f5f5f5;
  margin: 0 auto;
  .cityname {
    font-size: 80px;
    font-weight: 700;
    text-align: center;
    font-size: 宋体;
    margin-top: 0;
  }
  .introbox {
    display: flex;
    justify-content: center; // 主轴居中
    align-items: center; // 交叉轴居中
    max-width: 1200px; // 按需设置最大宽度
    margin: 0 auto; // 容器水平居中
    gap: 10px; // 元素间距
    padding: 20px 0; // 按需设置内边距

    .introleft,
    .introright {
      flex: 0 1 auto; // 不允许拉伸，允许收缩
      min-width: 60px; // 设置最小宽度防止过度压缩
      max-width: 300px; // 设置最大宽度限制

      img {
        width: 100%; // 图片宽度充满容器
        height: auto; // 高度自动保持比例
        object-fit: contain; // 保持图片完整比例
        display: block; // 消除图片底部间隙
      }
    }

    .info {
      // flex: 1;               // 占据剩余空间
      min-width: 100px; // 防止内容过窄
      text-align: center;
      padding: 0 10px; // 增加文字呼吸空间
      font-size: 20px;

      // 如果需要多行文字垂直居中
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  // .introbox{
  //   width: 100%;
  //   height: 30px;
  //   display: flex;
  //   align-items: center;
  //   .introleft,.introright{
  //     width: 50%;
  //     height: 100%;
  //     img{
  //       width: 100%;
  //       height: 100%;
  //     }
  //     .info{
  //       width: 100%;
  //       height: 100%;
  //       flex:1;
  //       font-size: 30px;
  //       font-weight: 700;
  //       text-align: center;
  //       line-height: 30px;
  //     }
  //   }
  // }
}

/* 选中某行时的背景色*/
.el-table__body tr.current-row > td {
   background-color: #92cbf1 !important;
}
 
/*鼠标移入某行时的背景色*/
.el-table__row  tr:hover > td {
   background-color: #92cbf1;
}

::v-deep .el-table__body-wrapper .el-table__cell{
  padding:10px 2px;
  font-size:15px;

}

 ::v-deep .el-table .el-table__cell{
  padding:4px  0;
 }

</style>